import './style.css';
import ReCAPTCHA from "react-google-recaptcha";
import React, {createRef} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingIcons from 'react-loading-icons'
import {useState} from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

const captchaRef = createRef(null)

function Form({stateChanger,dsrKey}) {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { t, i18n } = useTranslation();
  //const [success, setSuccess] = useState(false)
  var success = false;

  const handleSubmit = async (e) =>{
    e.preventDefault();
    if(loading) return false;
    var token = captchaRef.current.getValue();
    if (token.length === 0){
      notify("captcha_failed");
      return false
    }
    setLoading(true)
    
    let b64 = await getBase64(selectedFile);
    const requestDto = {
      Country : "",
      DsrApiKey : dsrKey,
      Email : e.target.email.value,
      FirstName : e.target.firstName.value,
      LastName : e.target.lastName.value,
      ProofOfIdentity : b64,
      RequestDescription : e.target.request_description.value,
      RequestorType : "",
      RequestType : e.target.request_type.value,
      Address : e.target.address.value,
      Recaptcha : token
    }

    await postForm(requestDto);
    
    if (!success){
      await new Promise((resolve) => setTimeout(resolve, 2000))
      setLoading(false)
      notify("error_occurred")
      captchaRef.current.reset();
      return false;
    }
    else {
      setLoading(false)
      complete()
      captchaRef.current.reset();
      return true;
    }
  }

  const notify = (messageKey) => toast.error((t(messageKey)));

  const complete = () => {
    stateChanger(true)
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className='column'>
          <ToastContainer position='top-center' hideProgressBar/>
          <div className='two_fields'>
            <input name="firstName" className='input_field' placeholder={t('firstName')} required></input>
            <input name="lastName" className='input_field' placeholder={t('lastName')} required></input>
          </div>
          <div className='two_fields'>
            <input name="address" className='input_field' placeholder={t('address')} required></input>
            <input name="email" type={"email"} className='input_field' placeholder={t('email')} required></input>
          </div>
          <div>
            <input name="image" type={"file"} accept={".jpg,.jpeg,.png"} onChange={(e) => setSelectedFile(e.target.files[0])} required></input>
          </div>
            <div>
            <select name="request_type" className='input_field' placeholder={t('request_type')} required>
              <option disabled value="">{t('request_type')}</option>
              <option value="Information">{t('request_type_1')}</option>
              <option value="Correction">{t('request_type_2')}</option>
              <option value="Objection">{t('request_type_3')}</option>
              <option value="Insight">{t('request_type_4')}</option>
              <option value="Deletion">{t('request_type_5')}</option>
              <option value="Dataportability">{t('request_type_6')}</option>
              <option value="Profiling">{t('request_type_7')}</option>
            </select>
          </div>
          <div>
            <input name="request_description" className='input_field' placeholder={t('request_description')} required></input>
          </div>
          <div>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            ref={captchaRef}
            hl={i18n.language}
          />
          </div>
          <div>
            <button type={"submit"} className='input_field submit grow'>
              {loading ? <LoadingIcons.ThreeDots fill={getComputedStyle(document.documentElement).getPropertyValue('--button_color_text')} style={{height: 13}}/> : t('submit')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  async function postForm(requestDto) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*'},
        body: JSON.stringify(requestDto) ,
        mode: 'cors'
    };
    
    await fetch('https://app.phinder.eu/api/dsr-request', requestOptions)
      .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
  
        //console.log(response)
        if (response.status != 200) {
            const error = (data && data.message) || response.status;
            success = false;
        }
        else {
          success = true;
        }
    })
    .catch(error => {
        success = false;
        console.error('There was an error!', error);
    })
  }
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
}

export default Form;
