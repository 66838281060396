import {useEffect, useState, useLayoutEffect} from 'react';
import Completed from "./completed"
import Form from "./form"
import { useTranslation, Trans} from 'react-i18next';

function Page() {
    const [completed, setCompleted] = useState(false);
    const {t, i18n} = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
      };

    useEffect(() => {
        setStyles()
        var language = getParamValue("ln")
        changeLanguage(language)
    }, []);

    var key = getParamValue("key");

    if (completed){
        return <Completed></Completed> 
    }
    else {
        return <Form stateChanger={setCompleted} dsrKey={key}></Form>
    }
}

let getParamValue = function(paramName)
{
    var url = window.location.search.substring(1); //get rid of "?" in querystring
    var color = window.location.hash;
    if(paramName === "color") return color;
    var qArray = url.replace('#', '&').split('&'); //get key-value pairs
    for (var i = 0; i < qArray.length; i++) 
    {
        var pArr = qArray[i].split('='); //split key and value
        if (pArr[0] === paramName){
        return pArr[1]; //return value
        }
    }
}

let setStyles = function() {
    let root = document.documentElement;
    let bg_color = getParamValue("color");
    if(bg_color){
        root.style.setProperty('--button_color', bg_color);
        var rgb = hexToRgb(bg_color);

        if ((rgb[0]*0.299 + rgb[1]*0.587 + rgb[2]*0.114) > 186) {
            root.style.setProperty('--button_color_text', "#000000");
        } else {
            root.style.setProperty('--button_color_text', "#FFFFFF");
        }
    }
}

const hexToRgb = hex =>
    hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                ,(m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16))


export default Page