import './style.css';
import { withTranslation } from 'react-i18next';
import animation_v2 from "./animations/checkmark_v2"
import { Player, } from '@lottiefiles/react-lottie-player';

function Completed({t,i18n}) {
  return (
    <div className='container'>
      <div>
        <Player
          autoplay
          keepLastFrame
          src={animation_v2}
          style={{ height: '200px', width: '200px' }}
        >
        </Player>
      </div>
      <h1 className='text center_text'>
        {t('thanks')}
      </h1>
      <p className='text center_text'>
        {t('thanks_text')}
      </p>
    </div>
  );
}

export default withTranslation()(Completed);